import React from "react";
import Contents from "../components/activitiesContents/contents";
import Footer from "../components/footer/footer";

const Activities = () => {
  return (
    <div>
      <Contents/>
      <Footer/>
    </div>
  );
};

export default Activities;
