import React from 'react'; 
import Container  from 'react-bootstrap/Container';
import './WelcomeUdiaa.css'
const WelcomeUdiaa = () =>{
    return( 
        <div className='outer-container'>
    <div className='inside-container'>
<p className='welcome'>
UDIAA is a student's led Professional Association that assembles students, academic staff 
and interested stakeholders at the University level under the common umbrella 
with the focus on helping students build their network and future careers. 
The association was inaugurated in 2023
</p>
    </div>
</div>
    );
}
export default WelcomeUdiaa;