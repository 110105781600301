import React from 'react';
import './contents.css';
import { Link } from 'react-router-dom';

const contents = [
  {
    title: 'CREATE AN EVENT, TOGETHER',
    explanation: 'UDIAA strives to promote professional and social events to aspiring business and internal auditing students for their professional and personal growth. We are always glad to collaborate with firms and clubs to create more opportunities. Contact us at <a href="mailto:info@udiaa.or.tz">info@udiaa.or.tz</a> to create an event together.',
    image: 'https://via.placeholder.com/150',
  },  
  {
    title: 'CORPORATE AND EXPOSURE',
    explanation: 'As an UDIAA member, you will learn about the different career opportunities available in business-related fields through access to our study visis, office tours and office hours where you have the opportunity to interact with potential employers. This is a unique opportunity where students are able to tour the firm\'s office to get a direct exposure to the working environment. This will also offer networking opportunities with the professionals from the firm.',
    image: 'https://via.placeholder.com/150',
  },
  {
    title: 'TRAINING AND WORKSHOPS',
    explanation: 'The perfect opportunity to improve your soft skills, gain tips and tricks to ace interviews, develop job ready skills at our workshops run by experienced industry professionals.  Some of the trainings and workshops include Resume workshops, Cover letter workshop, Recruiting workshop ,Professional communications workshops, public speaking workshop, professional behavior, excel trainings , audit report writing trainings and many more.',
    image: 'https://via.placeholder.com/150',
  },
  {
    title: 'LEADERSHIP PROGRAM',
    explanation: 'UDIAA prides itself as a leading society where members develop professional skills for the successful entrance into the corporate world. This program is designed for students aspiring to be leaders .',
    image: 'https://via.placeholder.com/150',
  },
  {
    title: 'MEET THE FIRMS',
    explanation: 'We aim to bridge the gap between students and professionals. We provide a platform where students build connections, showcase their skills, and develop their professional career. Meet the Firms helps connect firms with aspiring individuals that embrace your culture and ethics. You can help students explore job opportunities and understand the industry landscape. This is the most important networking event of the semester for students and professionals from the internal auditing community. There is a firm for everyone to network with. Take advantage and prepare for this amazing event, Build connections and develop careers. Firms sponsored raffles will be provided',
    image: 'https://via.placeholder.com/150',
  },
  {
    title: 'BLAST OFF',
    explanation: 'Blast off is a fun event where members are given the opportunity to interact with fellow members as well as enjoy some great food and games. In addition, there will be raffles during the event where members can win some awesome prizes. Dress code is casual .This will include games and activities to refresh, reflect and refocus. Meet like-minded individuals, connect with other students and meet other UDIAA members. This can be achieved at our Blast off',
    image: 'https://api.udiaa.or.tz/images/UDBS%20Freshers%20Event/CMX_6546.jpg'

  },
  {
    title: 'SOCIAL EVENTS',
    explanation: 'These are events that aims at giving back to society. Mostly done during the internal audit awareness month for community development. This include Charity event, beach cleanup, blood donation and so on ',
    image: 'https://via.placeholder.com/150'

  },
  {
    title: 'MOCK INTERVIEWS',
    explanation: 'There is no such thing as too much practice when it comes to interviews. Mock Interviews is an event where students can practice their interview skills with one or two professionals and receive direct feedback on your interviewing strength and weaknesses. This is also a great opportunity to network with professionals. ',
    image: 'https://via.placeholder.com/150'

  },
  {
    title: 'JOB READY SKILLS',
    explanation: 'UDIAA will help you prepare you for life after university. Some of these skills include writing professional, persuasive resumes and cover letters, as well as performing well in competency-based interviews and group assessment centres. This event is a first come, first serve basis. UDIAA  members are required to RSVP and submit their resumes to the chair members in charge of the event.',
    image: 'https://via.placeholder.com/150'

  },
  {
    title: 'INTERNAL AUDITING AWARENESS MONTH',
    explanation: 'MAY is our internal auditing Awareness month and UDIAA invites an everyone to their activities to promotw internal auditing . This includes speaker meetings , content creation, social activities and so on. You  may attend any of the events regardless of your affiliation. This is a great opportunity to learn more about internal auditing and network with the professionals. ',
    image: 'https://via.placeholder.com/150'
  },
  {
    title: 'MENTORING PROGRAM',
    explanation: 'Our mentoring program pairs our members with professionals or alumni members who are in the professional world. There are  activities such as coaching to a career paths , resume review, and more! Spots are limited and are on a first come first serve basis. This program hits full capacity every semester, so RSVP fast! .',
    image: 'https://via.placeholder.com/150'
  },
  {
    title: 'INTERNAL AUDIT STUDENTS CONFERENCE',
    explanation: 'This is is a fun, professional event towards the end of the semester. This is an opportunity for students to discuss on internal audit issues, learn from great speakers, network, eat good food and celebrate students achievements through our awards banquet and graduation ceremony.This event is open to everyone, even for non members.',
    image: 'https://via.placeholder.com/150'
  },
  {
    title: 'CASE COMPETITIONS',
    explanation: 'Do you want to expand your horizons and apply the theories you have learned in class to real life situations? Do you strive for a competitive environment and perform well under pressure? Do you want take your public speaking and analysis skills to the next level? If you answered YES then Crack the Case  is the competition for YOU!',
    image: 'https://via.placeholder.com/150'
  },
  {
    title: 'ARTICLE AND ESSAY WRITING COMPETITIONS',
    explanation: 'This is to all creative writers . Stand a chance to win a prize while showcasing your writing skills. The best articles and essays will be published on our websites',
    image: 'https://via.placeholder.com/150'
  },
];

const ContentItem = ({ title, explanation, image }) => (
  <div className="content-item">
    <h2>{title}</h2>
    <div className="green-line"></div>
    <p dangerouslySetInnerHTML={{ __html: explanation }}></p>
  </div>
);

const ContentGrid = ({ contents }) => (
  <div className="content-grid">
    {contents.map((content, index) => (
      <ContentItem key={index} {...content} />
    ))}
  </div>
);

const Contents = () => {
  return (
    <div>
        <div style={{ margin: '20px' }}> 
            <h2 className="activities-title">EVENTS AND ACTVITIES</h2>
            <p className="activities-paragraph">Our events prepare our members for life after University. 
                We offer a range of opportunities for professional, corporate, 
                social and personal development. To ensure that students are prepared 
                for their career, we find it  important to provide a broad range of 
                opportunities to target student development from multiple perspectives. 
                Highlighted below are some of the main events and activities that members 
                can participate in. Some events require RSVP and/or a payment, and some 
                don't require anything at all.
                Join <a href="https://forms.gle/aVhUy7PceTNygHZ5A">here</a> and receive emails on the activities and events at the start of the semester.
            </p>
            <p className="activities-paragraph"> The dates for these events and more are located <Link to="/calendar">here</Link>!</p>
       </div>
      <ContentGrid contents={contents} />
    </div>
  );
};

export default Contents;
